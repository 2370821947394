export const prices = {
  charDham5N6D: {
    price: "₹ 2,60,000",
    discountedPrice: "₹ 2,50,000",
  },
  charDham1N2D: { price: "₹ 15,00,000", discountedPrice: "₹ 14,50,000" },
  ekDham: {
    price: "₹ 5,00,000",
    discountedPrice: "₹ 4,80,000",
  },
  doDham: {
    price: "₹ 1,20,000",
    discountedPrice: "₹ 1,15,000",
  },
};
